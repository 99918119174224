import { render, staticRenderFns } from "./google-sso-button.vue?vue&type=template&id=132d8476&scoped=true"
import script from "./google-sso-button.vue?vue&type=script&lang=js"
export * from "./google-sso-button.vue?vue&type=script&lang=js"
import style0 from "./google-sso-button.vue?vue&type=style&index=0&id=132d8476&prod&lang=less&scoped=true"
import style1 from "./google-sso-button.vue?vue&type=style&index=1&id=132d8476&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "132d8476",
  null
  
)

export default component.exports