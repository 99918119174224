import Constants from '@constants'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __rootTc = getRootPluaralTranslator()

export const dateInputTypeOptions = () => [
  { text: 'Date Time', key: 'date_time' },
  { text: 'Date', key: 'date' },
]

export const refChoiceTypeOptions = (enableMsp = false) => [
  { text: 'Status', key: 'status' },
  { text: 'Location', key: 'location' },
  { text: 'Impact', key: 'impact' },
  { text: 'Priority', key: 'priority' },
  { text: 'Category', key: 'category' },
  { text: 'Urgency', key: 'urgency' },
  {
    text: 'Technician Group',
    key: 'technician_group',
  },
  { text: 'Requester', key: 'requester' },
  { text: 'Technician', key: 'technician' },
  { text: 'Department', key: 'department' },
  { text: 'Custom Field', key: 'customfield' },
]

export const refChoiceTypeMap = {
  status: 'status',
  location: 'location',
  impact: 'impact',
  priority: 'priority',
  category: 'category',
  urgency: 'urgency',
  technicianGroup: 'technicianGroup',
  requester: 'requester',
  technician: 'technician',
  department: 'department',
  dropdownField: 'dropdown',
  radioButtonField: 'dropdown',
  company: 'company',
}

export const moduleNameOptions = () => [
  { text: __rootTc('request'), key: Constants.REQUEST },
  { text: __rootTc('service_request'), key: Constants.SERVICE_CATALOG },
]

export const moduleNameSearchOptions = () => [
  { text: __rootTc('request'), key: Constants.REQUEST },
  { text: __rootTc('approval'), key: Constants.APPROVER },
  { text: __rootTc('service_request'), key: Constants.SERVICE_CATALOG },
]
